/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

// initial state for knacks slice
const initialState = {
  dashboardSideOpen: true,
  talentFeedSideOpen: true,
  searchedData: [],
};

// extrasSlice
export const extrasSlice = createSlice({
  name: "extras",
  initialState,
  reducers: {
    // toggle dashboard side
    toggleDashboardSide: (state) => {
      state.dashboardSideOpen = !state.dashboardSideOpen;
    },
    // toggle talent feed side
    toggleTalentFeedSide: (state) => {
      state.talentFeedSideOpen = !state.talentFeedSideOpen;
    },

    // set results of searched data
    setSearchedData: (state, action) => {
      state.searchedData = action.payload
    }
  },
});

export const { toggleDashboardSide, toggleTalentFeedSide, setSearchedData } =
  extrasSlice.actions;

export default extrasSlice.reducer;
