import Head from "next/head";
import React from "react";

export default function SharedHeader({
  title = "Use AI to Identify Superstars",
  description = "Use our groundbreaking games to unlock deep minds in minutes",
  image = "https://res.cloudinary.com/knackapp/image/upload/c_scale,w_1028/v1682882296/static/ascend/8d9c432b1c3ffb084eec12f0ed1d7320.png",
}: {
  title?: string;
  description?: string;
  image?: string;
}) {
  return (
    <Head>
      <title>{title}</title>
      <meta property="title" content={title} />
      <meta property="og:title" content={title} />
      <meta name="twitter:title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta name="twitter:description" content={description} />
      <meta property="image" content={image} />
      <meta property="og:image" content={image} />
      <meta name="twitter:image" content={image} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1 maximum-scale=1 user-scalable=no"
      />
    </Head>
  );
}
