/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useRouter } from "next/router";
import NProgress from "nprogress";
import { useEffect, useState } from "react";
import "styles/globals.css";
import "styles/nprogress.css";

import { PersistGate } from "redux-persist/integration/react";

import ErrorBoundary from "components/common/errorHandling/errorBoundary";
import OnlineNotifier from "global/OnlineNotifier";
import Spinner from "global/spinner";
import { GoogleAnalytics, event, usePageViews } from "nextjs-google-analytics";
import { Provider } from "react-redux";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { makeStore, persistor } from "redux/store";

export function reportWebVitals({ id, name, label, value }) {
  event(name, {
    category: label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
    value: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
    label: id, // id unique to current page load
    nonInteraction: true, // avoids affecting bounce rate.
  });
}

function App({ Component, pageProps }) {
  usePageViews();
  const router = useRouter();

  useEffect(() => {
    router.events.on("routeChangeStart", () => NProgress.start());
    router.events.on("routeChangeComplete", () => NProgress.done());
    router.events.on("routeChangeError", () => NProgress.done());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeToLoading = () => {
    document.title = "Loading...";
  };

  useEffect(() => {
    router.events.on("routeChangeStart", () => changeToLoading());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isWindow, setIsWindow] = useState(false);

  useEffect(() => {
    setIsWindow(true);
  }, []);

  return !isWindow ? (
    <Provider store={makeStore}>
      <OnlineNotifier />
      <ErrorBoundary>
        <Component {...pageProps} />
      </ErrorBoundary>
    </Provider>
  ) : (
    <Provider store={makeStore}>
      <PersistGate loading={<Spinner />} persistor={persistor}>
        <OnlineNotifier />
        <ErrorBoundary>
          <ToastContainer
            position="top-right"
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            transition={Slide}
            style={{ width: "350px" }}
          />
          <GoogleAnalytics />

          <Component {...pageProps} />
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  );
}

export default App;
