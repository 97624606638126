import SharedHeader from "global/head/header";
import Image from "next/image";
import Link from "next/link";
import React from "react";

export default function ErrorUIFallBack() {
  const refreshPage = () => {
    // refresh page and go home
    window.location.href = "/";
  };
  return (
    <>
      <SharedHeader title="Something went wrong" />
      <main className="bg-404-gradient flex h-screen min-h-screen w-full min-w-full flex-col gap-4 p-8 py-4 lg:gap-4">
        <Link
          href="/"
          className="clip-404-h1 w-max pr-2 text-[28px] font-extrabold tracking-[-0.09em] lg:text-[40px]"
        >
          KnackApp
        </Link>
        <div className="mt-6 flex h-[80%] flex-col items-center gap-5 md:mt-0 lg:mx-[3rem] lg:flex-row lg:justify-between">
          <div className="flex flex-col gap-6">
            <h1 className="text-[38px] font-extrabold leading-[100%] tracking-[-0.03em] text-white lg:text-[80px]">
              Ooops!
            </h1>
            <h1 className="text-[20px] font-extrabold leading-[100%] tracking-[-0.03em] text-white lg:text-[50px]">
              Something went wrong
            </h1>

            <button
              onClick={() => refreshPage()}
              className="btn-404-gr animateLinkAsButton btn-mobile-abs flex h-[60px] w-[330px] items-center justify-center rounded-[8px] text-[18px] font-semibold text-white"
            >
              Back to Homepage
            </button>
          </div>

          <div className="relative h-[25vh] w-[30vh] lg:h-[55vh] lg:w-[60vh] lg:self-auto">
            <Image
              src="https://res.cloudinary.com/knackapp/image/upload/v1654254431/static/extra/eu6dounftm1lwnzzy90h.png"
              alt="page not found"
              height={300}
              width={300}
              sizes="100vw"
              style={{
                width: "100%",
                height: "auto",
                objectFit: "contain",
              }}
            />
          </div>
        </div>
      </main>
    </>
  );
}
