/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

// initial state for pipeline slice
const initialState = {
  pipelineObj: {
    name: "",
    image:
      "https://res.cloudinary.com/knackapp/image/upload/v1653048104/static/sample/pawctdnubohkaglv5eab.png",
    logo: "https://res.cloudinary.com/knackapp/image/upload/v1653048103/static/sample/mzxzalrgbttse47p4bhq.webp",
    title: "",
    description: "",
    owner: "",
  },
  idOnEdit: "",
  toUploadFiles: {
    logo: "",
    displayImage: "",
  },
};

// pipeline slice
export const pipelineSlice = createSlice({
  name: "pipeline",
  initialState,
  reducers: {
    resetPipeline: () => initialState,
    // set pipeline obj
    setPipelineObj: (state, action) => {
      state.pipelineObj = action.payload;
    },
    // get pipeline obj
    getPipelineObj: (state) => state.pipelineObj,
    // set to upload files
    setToUploadFilesPipeline: (state, action) => {
      state.toUploadFiles = action.payload;
    },
    // get toUpload fi;es
    getToUploadFiles: (state) => state.toUploadFiles,
    // set id on edit
    setIdOnEditPipeline: (state, action) => {
      state.idOnEdit = action.payload;
    },
    // get id on edit
    getIdOnEditPipeline: (state) => state.idOnEdit,
  },
});

export const {
  resetPipeline,
  setPipelineObj,
  getPipelineObj,
  setToUploadFilesPipeline,
  getToUploadFiles,
  setIdOnEditPipeline,
  getIdOnEditPipeline,
} = pipelineSlice.actions;

export default pipelineSlice.reducer;
