/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInfo: {
    _id: "",
    role: "",
    hasFetchedProfile: false,
    email: "",
    handle: "",
    userName: "",
  },
  isLoading: false,
  token: "",
  refreshToken: "",
  isError: null,
  isSuccess: null,
  message: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuth: () => initialState,
    // set userinfo
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    resetUserProfile: (state) => {
      state.userInfo = initialState.userInfo;
    },
    // set token
    setToken: (state, action) => {
      state.token = action.payload;
    },
    // set refresh token
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
    },
    // get user info
    getUserInfo: (state) => state.userInfo,
    // get token
    getToken: (state) => state.token,
    // get refresh token
    getRefreshToken: (state) => state.refreshToken,
  },
});
export const {
  resetAuth,
  setUserInfo,
  setToken,
  getUserInfo,
  getToken,
  setRefreshToken,
  getRefreshToken,
  resetUserProfile,
} = authSlice.actions;
export default authSlice.reducer;
