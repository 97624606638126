/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  workExperiences: [],
  projects: [],
  education: [],
  talents: [],
  talentDetails: {},
  superlikesPayment: {},
  completedGames: [],
};

export const talentSlice = createSlice({
  name: "talent",
  initialState,
  reducers: {
    resetTalent: () => initialState,
    setTalentDetails: (state, action) => {
      state.talentDetails = action.payload;
    },
    setWorkExperiences: (state, action) => {
      state.workExperiences = action.payload;
    },

    setTalents: (state, action) => {
      state.talents = action.payload;
    },
    setSuperlikesPayment: (state, action) => {
      state.superlikesPayment = action.payload;
    },
    setCompletedGames: (state, action) => {
      state.completedGames = action.payload;
    },
  },
});

export const {
  resetTalent,
  setTalentDetails,
  setWorkExperiences,
  setTalents,
  setSuperlikesPayment,
  setCompletedGames,
} = talentSlice.actions;

export default talentSlice.reducer;
