/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

// initial state for knacks slice
const initialState = {
  knacks: [],
  templates: [],
  knackGroups: [],
  skillGroups: [],
  templateCategoryGroups: [],
  talentResults: [],
};

// knacks slice
export const knacksSlice = createSlice({
  name: "knacks",
  initialState,
  reducers: {
    // add 1 knack to knacks array
    addKnack: (state, action) => {
      state.knacks.push(action.payload);
    },
    // remove knack from knacks array
    removeKnack: (state, action) => {
      state.knacks = state.knacks.filter(
        (knack) => knack.id !== action.payload
      );
    },
    // set knacks array
    setKnacks: (state, action) => {
      state.knacks = action.payload;
    },
    // set knack groups
    setKnackGroups: (state, action) => {
      state.knackGroups = action.payload;
    },
    // set templates array
    setTemplates: (state, action) => {
      state.templates = action.payload;
    },
    // get knacks array
    getKnacks: (state) => state.knacks,
    // get templates array
    getTemplates: (state) => state.templates,
    // get knack groups array
    getKnackGroups: (state) => state.knackGroups,
    // reset knacks array
    resetKnacks: () => initialState,
    // reset templates array
    resetTemplates: (state) => {
      state.templates = [];
    },
    // set template category groups
    setTemplateCategoryGroups: (state, action) => {
      state.templateCategoryGroups = action.payload;
    },
    // get template category groups array
    getTemplateCategoryGroups: (state) => state.templateCategoryGroups,
    // set skills groups
    setSkillGroups: (state, action) => {
      state.skillGroups = action.payload;
    },
    // get skills groups array
    getSkillGroups: (state) => state.skillGroups,
    // set talent results
    setTalentResults: (state, action) => {
      state.talentResults = action.payload;
    },
    // get talent results array
    getTalentResults: (state) => state.talentResults,
  },
});

export const {
  addKnack,
  removeKnack,
  setKnacks,
  getKnacks,
  resetKnacks,
  setTemplates,
  getTemplates,
  resetTemplates,
  setKnackGroups,
  getKnackGroups,
  setTemplateCategoryGroups,
  getTemplateCategoryGroups,
  setSkillGroups,
  getSkillGroups,
  setTalentResults,
  getTalentResults,
} = knacksSlice.actions;

export default knacksSlice.reducer;
